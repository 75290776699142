import { NgModule } from '@angular/core';
import { TfflSharedModule } from '@tffl/shared';
import { SharedImportsModule } from 'projects/statsApp/src/app/shared-imports/shared-imports.module';
import { LeaguePickerComponent } from './league-picker/league-picker.component';

@NgModule({
    imports: [
        SharedImportsModule,
        TfflSharedModule,
    ],
    declarations: [
        LeaguePickerComponent,
    ],
    exports: [
        LeaguePickerComponent,
    ],
})
export class SharedModule { }
