import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Division, League, LeaguesService, Program, ProgramsService, Season, SeasonsService } from '@tffl/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface LeagueValues {
    leagueId: any,
    seasonId: any,
    programId: any,
    divisionId: any,
}

@Component({
    selector: 'app-league-picker',
    templateUrl: './league-picker.component.html',
    styleUrls: ['./league-picker.component.scss']
})
export class LeaguePickerComponent implements OnInit, OnDestroy {

    @Input() leagueIdControl: FormControl;
    @Input() seasonIdControl: FormControl;
    @Input() programIdControl: FormControl;
    @Input() divisionIdControl: FormControl;

    divisions: Division[] = [];
    programs: Program[] = [];
    seasons: Season[] = [];
    leagues: League[] = [];

    visibleDivisions: Division[] = [];

    constructor(
        private programsService: ProgramsService,
        private seasonsService: SeasonsService,
        private leaguesService: LeaguesService,
    ) {
    }

    ngOnInit() {

        if (!this.leagueIdControl || !this.leagueIdControl.value) {
            throw new Error('League Id must be set');
        }

        this.initForm();
        this.loadLeagues();
    }

    ngOnDestroy() { }

    private initForm() {
        console.log(this);

        // this.changes.emit(null);

        this.seasonIdControl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe((seasonId: any) => {
            this.loadRelatedToSeason(seasonId);
        });

        this.programIdControl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe((programId: any) => {
            this.filterDivisions(programId);
        });

        this.loadSeasons(this.leagueIdControl.value);

        if (this.seasonIdControl && this.seasonIdControl.value) {
            this.loadRelatedToSeason(this.seasonIdControl.value);
        }
        if (this.programIdControl && this.programIdControl.value) {
            this.filterDivisions(this.programIdControl.value);
        }
    }

    private loadLeagues() {
        this.leaguesService.getLeagues().subscribe(leagues => {
            this.leagues = leagues;
        });
    }

    private loadSeasons(leagueId) {
        this.seasonsService.getSeasons(leagueId).subscribe(seasons => {
            this.seasons = seasons;
        })
    }

    private loadRelatedToSeason(seasonId) {
        this.programsService.getBySeason(seasonId).subscribe(p => {
            this.programs = p;
            console.log(this.programs)
            let pId = this.programIdControl.value;
            if (pId && !this.programs.some(p => pId == p.id)) {
                this.programIdControl.setValue(null);
                this.programIdControl.markAsTouched();
            }
        });
        this.programsService.getDivisions(seasonId).subscribe(d => {
            this.divisions = d;
            if (this.programIdControl.value) {
                this.filterDivisions(this.programIdControl.value);
            } else {
                this.visibleDivisions = [];
            }
        });
    }

    private filterDivisions(programId) {
        this.visibleDivisions = this.divisions.filter(d => d.programId == programId);

        let divControl = this.divisionIdControl;
        let divValue = divControl.value;

        if (divValue != null && !this.visibleDivisions.some(d => d.id == divValue)) {
            divControl.setValue(null);
        }

        if (!this.visibleDivisions.length && divControl.enabled) {
            divControl.disable({ emitEvent: false });
        } else if (this.visibleDivisions.length && divControl.disable) {
            divControl.enable({ emitEvent: false });
        }
    }
}
